$theme: ekonomi;
@use "design-system" as ds;

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
}

.buttonText {
  &.default {
    display: none;
    speak: always; // Always use this value for audio

    @media (min-width: ds.$screen-size--small) {
      display: block;
    }
  }
  &.smallScreen {
    display: block;
    speak: never; // Never use this value for audio. It's usually just shorter bc space concerns.

    @media (min-width: ds.$screen-size--small) {
      display: none;
    }
  }
  &.hidden {
    @include ds.a11y-hidden();
  }
}

.limitWidth {
  // When button text is dynamic
  .buttonText {
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }
}
