$theme: ekonomi;
@use "design-system" as ds;

.footer {
  display: flex;
  flex-direction: column;
  max-width: ds.$content-width--max;
  align-items: center;
  margin: 0 auto;
  gap: ds.spacing();
  padding: ds.spacing(medium) ds.spacing();
  @include ds.typography(underline, $theme: $theme);
  @include ds.text-color(secondary, $theme: $theme);
}

.footerSection {
  @include ds.stack(base);
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2px;

  &:first-child {
    @include ds.stack(none);
  }
}

.footerImage {
  @include ds.darkmode {
    // Hack to make dark image logo whitish in dark mode
    filter: invert(50%);
  }
}
