$theme: ekonomi;
@use "design-system" as ds;

$button-border-width: 1px;

.button {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  @include ds.typography(button-text, $theme);
  border-width: $button-border-width;
  border-style: solid;
  border-radius: 100vi;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

// SIZES
// -----

.large {
  padding: ds.spacing() ds.spacing(medium);
}

.small {
  padding: 10px ds.spacing(medium);
}

.tiny {
  padding: 6px 12px;
}

.compact {
  padding: 6px 12px;
  height: 40px;
}

// VARIANTS
// --------

.primary {
  @include ds.border(brand, $a: $button-border-width, $theme: $theme);
  @include ds.element-background(brand, $theme: $theme);
  @include ds.text-color(tertiary, $theme: $theme);

  transition:
    border-color 0.2s,
    background-color 0.2s;

  &.icon {
    @include ds.colorise-icon(text, tertiary, $theme: $theme);
  }

  &:hover {
    @include ds.border(
      brand--prominent,
      $a: $button-border-width,
      $theme: $theme
    );
    @include ds.element-background(brand--prominent, $theme: $theme);
  }
}

.secondary {
  @include ds.border(brand, $a: $button-border-width, $theme: $theme);
  @include ds.text-color(brand, $theme: $theme);
  transition:
    border-color 0.2s,
    background-color 0.2s,
    color 0.2s;

  &.icon {
    @include ds.colorise-icon(text, brand, $theme: $theme);
  }

  &:hover {
    @include ds.border(
      brand--prominent,
      $a: $button-border-width,
      $theme: $theme
    );
    @include ds.text-color(brand--prominent, $theme: $theme);

    &.icon {
      @include ds.colorise-icon(text, brand--prominent, $theme: $theme);
    }
  }
}

.icon {
  // A variant with only an icon (no background and no border)
  border-color: transparent;

  &.icon {
    @include ds.colorise-icon(text, primary, $theme: $theme);
  }

  &:hover {
    .icon {
      @include ds.colorise-icon(text, brand--prominent, $theme: $theme);
    }
  }

  &:focus {
    border-color: transparent;
    outline: transparent;
  }
}

.text {
  // A link-like variant (only text, no background and no border)
  border-color: transparent;
  @include ds.text-color(brand, $theme: $theme);
  transition: color 0.2s;

  &.icon {
    @include ds.colorise-icon(text, brand, $theme: $theme);
  }

  &:hover {
    @include ds.text-color(brand--prominent, $theme: $theme);

    &.icon {
      @include ds.colorise-icon(text, brand--prominent, $theme: $theme);
    }
  }

  &:focus {
    border-color: transparent;
    outline: transparent;
  }
}

// MODIFIERS
// ---------

.premium {
  &.primary {
    @include ds.border(premium, $a: $button-border-width, $theme: $theme);
    @include ds.element-background(premium, $theme: $theme);

    &:hover {
      @include ds.border(
        premium--prominent,
        $a: $button-border-width,
        $theme: $theme
      );
      @include ds.element-background(premium--prominent, $theme: $theme);
    }
  }

  &.secondary {
    @include ds.border(premium, $a: $button-border-width, $theme: $theme);
    @include ds.text-color(premium, $theme: $theme);

    &:hover {
      @include ds.border(
        premium--prominent,
        $a: $button-border-width,
        $theme: $theme
      );
      @include ds.text-color(premium--prominent, $theme: $theme);
    }
  }

  &.text {
    @include ds.text-color(premium, $theme: $theme);

    &:hover {
      @include ds.text-color(premium--prominent, $theme: $theme);
    }
  }
}
