$theme: ekonomi;
@use "design-system" as ds;

.overlay {
  position: fixed;
  top: ds.$header-main-elements-height;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: ds.$z-index--menu;
  @include ds.background(card--subtle, $theme: $theme);
  overflow: auto;
}

.hidden {
  display: none;
}

.container {
  flex-grow: 1;
  width: 100vw;
  height: 100%;
  padding-bottom: ds.spacing(medium);
  box-sizing: border-box;
  margin: 0 auto;
  @media (min-width: ds.$screen-size--small) {
    max-width: ds.$site-width--default;
  }

  @media (min-width: ds.$screen-size--medium) {
    max-width: ds.$site-width--wide;
  }
}
