$theme: ekonomi;
@use "design-system" as ds;

.newsMixCategoryContainer {
  width: 100%;
  display: flex;
  gap: ds.spacing();
  flex-direction: column;
}

.titleContainer {
  display: flex;
  gap: ds.spacing(base);
  align-items: center;
}

.categoryTitle {
  @include ds.typography(meta, $theme);
}

.disabled {
  pointer-events: none;
}
