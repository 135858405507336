$theme: ekonomi;
@use "design-system" as ds;

.newsMixPage {
  max-width: 688px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto ds.spacing(medium) auto;
  box-sizing: border-box;
  overflow: hidden;
}

.pageDescription {
  margin-top: ds.spacing(base);
  @include ds.typography(body, $theme);
}

.pageTitle {
  @include ds.typography(h1, $theme);
}

.ctaContainer {
  margin-top: ds.spacing(regular-increased);
  padding: 0 ds.spacing();
  @media (min-width: ds.$screen-size--medium) {
    padding: 0 ds.spacing(medium);
  }
}

.heading {
  padding: 0 ds.spacing();
  @media (min-width: ds.$screen-size--medium) {
    padding: 0 ds.spacing(medium);
    margin: ds.spacing(medium) 0;
  }
  margin: ds.spacing() 0;
}
